import React from 'react';
import HeroBanner from '../components/sections/hero-banner';
import LogoBanner from '../components/sections/logo-banner';
import { useStaticQuery, graphql } from 'gatsby';
import Feature from '../components/sections/industry/feature-block';
import WhyPatronscan from '../components/sections/why-patronscan';
import TripleFeature from '../components/sections/triple-feature';
import ConnectWithUs from '../components/sections/connect-with-us';
import { getDemoLink } from '../services/demo-selector';
import { IndustryType } from '../components/sections/logo-banner';
import { Route } from '../models/site-links';
import SEO from '../components/seo';
import './fake-id-scanner.css';

const FakeIdScanner = () => {
  const data = useStaticQuery(graphql`
    query {
      fakeIDHero: file(relativePath: { eq: "hero-images/fakeID-hero-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secure: file(relativePath: { eq: "icons/secure.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lawenforcement: file(relativePath: { eq: "icons/law_enforcement.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      underageFakeID: file(relativePath: { eq: "fake-id-scanner/fake-hawaii.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      highspeedscanning: file(relativePath: { eq: "icons/high_speed_scanning.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scanHistory: file(relativePath: { eq: "fake-id-scanner/scanhistory.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pibox: file(relativePath: { eq: "fake-id-scanner/pibox.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakePibox: file(relativePath: { eq: "fake-id-scanner/fakepibox.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstLogo: file(relativePath: { eq: "logosforbanner/home/avis_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondLogo: file(relativePath: { eq: "logosforbanner/home/Four-Crowns-Hotel2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thirdLogo: file(
        relativePath: { eq: "logosforbanner/home/fairfield-property-management-logo2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fourthLogo: file(relativePath: { eq: "logosforbanner/home/cactus.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barcodeScanning: file(relativePath: { eq: "feature-images/barcode_no_bueno.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scannerGuts: file(relativePath: { eq: "fake-id-scanner/scanner-inside.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      passportScanner: file(relativePath: { eq: "fake-id-scanner/passportscan.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="fake-id-scanner">
      <SEO
        title="Advanced Fake ID Detection | Patronscan"
        description="Patronscan authenticates IDs using 6,000 data points under dynamic lightning. Accurately spot fake IDs and avoid false positives. Affordable hardware options."
      />
      <HeroBanner
        heroImage={data.fakeIDHero.childImageSharp.fluid}
        heading="Trusted Fake ID Detection"
        subHeading="Reliably catch fakes in seconds with the world's best ID scanners."
        videoLink="https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1"
        showVideo
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="FakeIdScannerHero:BookDemo"
      ></HeroBanner>
      <LogoBanner industry={IndustryType.FakeIDScanner} />
      <Feature
        image={data.pibox.childImageSharp.fluid}
        rightAlign
        heading="Getting harder"
        headingThin="to spot fake IDs?"
        subHeading="Fake IDs are better cheaper, and easier to buy than ever."
        description="By verifying millions of IDs every week, Patronscan detects fake IDs faster and more reliably than the competition. To limit the number of false positives, our proprietary software takes into account both discrepencies from real IDs and positive matches to known fake IDs."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="FakeIdScannerFeature:HarderToSpotFakeIds"
      />
      <Feature
        image={data.barcodeScanning.childImageSharp.fluid}
        heading="Fake IDs easily"
        headingThin="pass basic scanners"
        subHeading="Barcodes are only one point of reference. Patronscan checks over 6,000."
        description="Get the right technology to detect fake IDs. Patronscan fake ID scanners work by evaluating pixels for correctness while analyzing an ID's security features under white and infrared lighting conditions."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="FakeIdScannerFeature:FakeIdsEasily"
      />
      <Feature
        rightAlign
        image={data.scannerGuts.childImageSharp.fluid}
        heading="Leading identity"
        headingThin="verification technology"
        subHeading="Patronscan's software has been developed over 15 years with continuous updates."
        description="The most popular fake ID suppliers attempt to replicate the fonts, security features and design details of real IDs. With Patronscan's nearest-neighbour algorithm, the slightest pixel discrepancies are used to flag IDs as suspicious."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="FakeIdScannerFeature:LeadingTechnology"
      />
      <Feature
        image={data.passportScanner.childImageSharp.fluid}
        heading="Validate over"
        headingThin="4,500 different IDs"
        subHeading="Fraudsters almost always use out-of-state IDs that that are less familiar to employees."
        description="Fraudsters often use IDs outside of your jurisdiction to trick you. It's why we keep our ID library updated with driver's licenses, military cards,
                    status cards and passports issued around the world."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="FakeIdFeature:4500Ids"
      />
      <WhyPatronscan />
      <div className="fakeID-trifeature-spacing">
        <TripleFeature
          firstFeatureIcon={data.secure.childImageSharp.fluid}
          secondFeatureIcon={data.lawenforcement.childImageSharp.fluid}
          thirdFeatureIcon={data.highspeedscanning.childImageSharp.fluid}
          firstHeadingText="High Speed Scanning"
          secondHeadingText="Secure and Private"
          thirdHeadingText="Trusted by Law Enforcement"
          firstDescriptionText="Don't let an ID scanner slow you down. Patronscan will quickly authenticate IDs from your surrounding region in 3-4 seconds. IDs from halfway across the world take just a touch longer."
          secondDescriptionText="We perform frequent legal reviews of our technology to ensure it complies with privacy laws in every jurisdiction in which we operate. Data is securely stored and never sold."
          thirdDescriptionText="Patronscan is recommended by law enforcement agencies across the country to prevent identity fraud and foster safe environments."
        />
      </div>
      <ConnectWithUs
        buttonId="FakeIdScannerConnect"
        promptText="Request pricing from one of our experts or book a live demo to see why Patronscan is the most trusted fake ID scanners."
      />
    </div>
  );
};

export default FakeIdScanner;
